.articleContent {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.6;
}

.articleContent p {
    margin-bottom: 1rem;
}

.articleContent br {
    display: block;
    content: "";
    margin: 0.5rem 0;
}
